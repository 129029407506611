import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/SEO"
import Hero from "../components/Hero/Hero"
import BGImg from "../components/LargeBGImg/LargeBGImg"
import TwoColumn from "../components/TwoColumn/TwoColumn"
import ImageGrid from "../components/ImageGrid/ImageGrid"
import ImageWithText from "../components/ImageWithText/ImageWithText"

const ArtPage = ({ data }) => {

  // Grab variable from .md file

  // Hero Sections
  const hero = data.hero.edges[0].node.frontmatter.heroImage.childImageSharp.gatsbyImageData;
  const heroAlt = data.hero.edges[0].node.frontmatter.heroImageAlt;
  const identifier = data.hero.edges[0].node.frontmatter.identifier;
  const heroDescription = data.hero.edges[0].node.frontmatter.description;

  // Large BG Images
  const earlyAgeBGContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Story')
  const politicalEngagementContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Art And The Public Good')
  const foundingOfMOCAContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Founding Of MOCA')
  const theBroadFoundationContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'The Broad Art Foundation')
  const contemporaryArtMuseumContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Broad Contemporary Art Museum At LACMA')
  const artEducationPlilanthropy = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Arts Education Philanthropy')
  const theBroadStage = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'The Broad Stage')
  const theBroadColorful = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'The Broad')


  // Two Column Sections
  const twoartPhilanthropyTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'artPhilanthropyTop')
  const twoartPhilanthropy = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'artPhilanthropy')
  const twoearlyCollectingTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'earlyCollectingTop')
  const twoearlyCollecting = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'earlyCollecting')
  const twobroadSupport = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'broadSupport')
  const twoTBFM = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'tbfm')
  const tworelationshipsWithArtists = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'relationshipsWithArtists')
  const twoartMuseum = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'artMuseum')
  const twoCommitted = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'committed')
  const twoLacmaCampus = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'LacmaCampus')
  const twoMsuAndArts = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'MsuAndArts')
  const twoMsuAndArtsTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'MsuAndArtsTop')
  const twoeightYears = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'eightYears')
  const twoPerformingArts = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'PerformingArts')
  const twobroadInfoTop = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'broadInfoTop')
  const twobroadInfo = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'broadInfo')
  const twotheChoice = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'theChoice')
  const eliKnewText = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'eliKnewText')


  // Image Grids
  const imageGridbroadExterior = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'broadExterior')
  const theBroads = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'theBroads')
  const yourBody = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'yourBody')
  const lights = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'lights')
  const broadSideView = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'broadSideView')
  const eliKnewWide = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'eliKnewWide')

  // Image With Text
  const artCollageComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'artCollage')
  const buildingExteriorComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'buildingExterior')
  const blackWhiteImageComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'blackWhiteImage')
  const mocaGrandComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'mocaGrand')
  const broadWallArtComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'broadWallArt')
  const manyTestimentsComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'manyTestiments')
  const broadWallArtTwoComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'broadWallArtTwo')
  const basquiatArtComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'basquiatArt')
  const formerOfficeComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'formerOffice')
  const establishedTheBroadComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'establishedTheBroad')
  const costructionSiteComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'costructionSite')
  const costructionSiteTwoComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'costructionSiteTwo')
  const donationComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'donation')
  const donationTwoComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'donation2')
  const coneComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'cone')
  const ribbonComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'ribbons')
  const msuWorkingComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'msuWorking')
  const theatreComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'theatre')
  const calledHimComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'calledHim')
  const eliKnew = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'eliKnew')



  // Hero Text Positions
  const positions = {
    rowOneWrapper: {
      classes: "row-wrapper one",
      TextRow1: {
        classes: "mb-200",
          text1: {
            value: "A",
            classes: "ten Rightauto"
          },
          text2: {
            value: "Fascination",
            classes: "fifty"
          }
      },
      TextRow2: {
        classes: "center",
          text1: {
            value: "With",
            classes: "thirty text-right"
          },
          text2: {
            value: "",
            classes: "twenty Leftauto"
          }
      },
    },
    rowTwoWrapper: {
      classes: "row-wrapper two",
      TextRow1: {
        classes: "",
          text1: {
            value: "The",
            classes: "twenty"
          },
          text2: {
            value: "Art",
            classes: "twenty Leftauto"
          }
      },
      TextRow2: {
        classes: "",
          text1: {
            value: "Of",
            classes: ""
          },
          text2: {
            value: " ",
            classes: ""
          }
      },
      TextRow3: {
        classes: "",
          text1: {
            value: "Our",
            classes: "forty"
          },
          text2: {
            value: "Time",
            classes: "forty text-right Leftauto"
          }
      },
    }
  }
  return (
    <>
      <Seo title={"Eli Broad | Art"} description={"For over 50 years, Eli and Edye endeavored to deepen access to arts and culture in their hometown of Los Angeles and around the world, believing that everyone should have the opportunity to experience artists’ insights into human nature and society."} />
      <Hero heroimage={hero} heroimagealt={heroAlt} identifier={identifier} positions={positions} description={heroDescription} />

      <BGImg content={earlyAgeBGContent} />

      <BGImg content={politicalEngagementContent} />

      <TwoColumn content={twoartPhilanthropyTop} type="top" />

      <TwoColumn content={twoartPhilanthropy} type="bottom" />

      <ImageWithText content={buildingExteriorComponent}   />

      <ImageWithText content={artCollageComponent}  />

      <TwoColumn content={twobroadSupport}/>

      <ImageGrid content={imageGridbroadExterior} />

      <BGImg content={foundingOfMOCAContent} />

      <TwoColumn content={twoearlyCollectingTop} type="top" />

      <TwoColumn content={twoearlyCollecting} type="bottom" />

      <ImageWithText content={blackWhiteImageComponent} headerContent={true} />

      <ImageWithText content={mocaGrandComponent} />

      <ImageWithText content={broadWallArtComponent} headerContent={true} />

      <ImageWithText content={manyTestimentsComponent} headerContent={true} />

      <BGImg content={theBroadFoundationContent} />

      <TwoColumn content={twoTBFM}/>

      <ImageWithText content={broadWallArtTwoComponent} headerContent={true} />

      <ImageWithText content={basquiatArtComponent} headerContent={true} />

      <TwoColumn content={tworelationshipsWithArtists}/>

      <ImageWithText content={formerOfficeComponent} headerContent={true} />

      <ImageWithText content={establishedTheBroadComponent} headerContent={true} />

      <BGImg content={contemporaryArtMuseumContent} />

      <TwoColumn content={twoartMuseum}/>

      <ImageWithText content={costructionSiteComponent} headerContent={true} />

      <TwoColumn content={twoCommitted}/>

      <ImageWithText content={costructionSiteTwoComponent} headerContent={true} />

      <ImageWithText content={donationComponent} headerContent={true} />

      <ImageWithText content={donationTwoComponent} headerContent={false} />

      {/* <TwoColumn content={twoLacmaCampus}/> */}

      <BGImg content={artEducationPlilanthropy} />

      <TwoColumn content={twoMsuAndArtsTop} type="top" />

      <TwoColumn content={twoMsuAndArts} type="bottom" />

      <ImageWithText content={coneComponent} headerContent={true} />

      <ImageWithText content={ribbonComponent} headerContent={true} />

      <TwoColumn content={twoeightYears}/>

      <ImageWithText content={msuWorkingComponent} headerContent={true} />

      <BGImg content={theBroadStage} />

      <TwoColumn content={twoPerformingArts}/>

      <ImageWithText content={theatreComponent} headerContent={true} />

      <ImageGrid content={theBroads} />

      <BGImg content={theBroadColorful} />

      <TwoColumn content={twobroadInfoTop} type="top" />

      <TwoColumn content={twobroadInfo} type="bottom" />

      <ImageGrid content={yourBody} />

      <ImageGrid content={lights} />

      <TwoColumn content={twotheChoice}/>

      <ImageWithText content={calledHimComponent} headerContent={true} />

      {/* <ImageWithText content={eliKnew} headerContent={true} /> */}

      <ImageGrid content={eliKnewWide} />

      <TwoColumn content={eliKnewText}/>

      <ImageGrid content={broadSideView} />

      <div class="continue-link">
        <Link to="/los-angeles">Continue to Los Angeles</Link>
      </div>

    </>
  )
}

export default ArtPage

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {frontmatter: {identifier: {eq: "art"}}}) {
      edges {
        node {
          frontmatter {
            heroImage {
              childImageSharp {
                gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            heroImageAlt
            identifier
            intro
            title
            description
          }
        }
      }
    }
    largeBGImg: allMarkdownRemark(filter: {frontmatter: {component: {eq: "LargeBGImg"}, page: {eq: "art"}}}) {
      edges {
        node {
          ...largeBGImgQuery
        }
      }
    }
    twoColumn: allMarkdownRemark(filter: {frontmatter: {page: {eq: "art"}, component: {eq: "twocolumn"}}}) {
      edges {
        node {
          ...twoColumnQuery
        }
      }
    }
    imageGrid: allMarkdownRemark(filter: {frontmatter: {page: {eq: "art"}, component: {eq: "imageGrid"}}}) {
      edges {
        node {
          ...imageGridQuery
        }
      }
    }
    imageWithText: allMarkdownRemark(filter: {frontmatter: {component: {eq: "imageWithText"}, page: {eq: "art"}}}) {
      edges {
        node {
          ...imageWithTextQuery
        }
      }
    }
  }
`


